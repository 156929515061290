<template>
	<section class="container">
		<div>
			<h1>{{ $t('global.settings') }}</h1>
		</div>
		<div class="row mt-2 mb-2">
			<div class="col-12">
				<hr />
			</div>
		</div>

		<div class="row justify-content-center">
			<div class="col-12 mt-3">
				<h3>
					<b>Slet brugeren</b>
				</h3>
			</div>
			<div class="col-auto">
				<BaseActionButton class="btn-delete" type="button" v-on:on-click="onSubmit" v-if="$can('delete', 'User')"> Slet </BaseActionButton>
			</div>
			<div class="col-12">
				<hr />
			</div>
		</div>
	</section>
</template>

<script>
import Swal from 'sweetalert2'

export default {
	name: 'UserSettings',
	components: {},
	data() {
		return {
			clicked: false,
			disabled: true,
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
	},
	methods: {
		async onSubmit() {
			Swal.fire({
				title: 'Slette brugeren?',
				text: 'Vil du slette brugeren?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ja slet!',
				showLoaderOnConfirm: true,
				preConfirm: async () => {
					try {
						var result = await this.$store.dispatch('userVuex/deleteUser', this.userId)
						return result
					} catch (error) {
						console.log(error)
						Swal.showValidationMessage(`Request failed: ${error.message}`)
					}
				},
			}).then((result) => {
				if (result.isConfirmed) {
					this.toast('Success', 'Brugeren er blevet slettet', true)
					this.$router.push({ name: 'UserList' })
					this.clicked = false
				}
			})
		},
	},
	computed: {
		userId() {
			return this.$route.params.id
		},
		company() {
			return this.$store.getters['userVuex/user']
		},
		fullName() {
			return this.$store.getters['userVuex/fullName']
		},
	},
}
</script>

<style></style>
